






















































































import axios from 'axios'
import csv from 'csvtojson'
import VueApexCharts from 'vue-apexcharts'
import {
  defineComponent,
  onBeforeMount,
  ref,
} from 'vue-demi'

const chartOptions = {
  chart: {
    height: 350,
    zoom: { type: 'x' },
  },
  dataLabels: { enabled: false },
  grid: {
    xaxis: { lines: { show: true } },
    yaxis: { lines: { show: true } },
  },
  xaxis: { type: 'datetime' },
}

export default defineComponent({
  name: 'Chart',

  components: { apexchart: VueApexCharts },

  setup () {
    const series = ref<any>(null)
    async function loadData () {
      const { data } = await axios.get(
        'https://static.dwcdn.net/data/Nmq7h.csv',
      )
      const parsed = await csv().fromString(data)
      const last30Days = +(new Date().setDate(new Date().getDate() - 30))
      const items = parsed.map(item => {
        item.fecha = +new Date(item.fecha)
        return item
      }).filter(item => item.fecha >= last30Days)

      series.value = [
        {
          name: 'Hospitalizados diarios (últimos 30 días)',
          data: items.map((item: any) => [
            item.fecha,
            +item['Hospitalizados diarios'] || 0,
          ]),
        },
        {
          name: 'Media UCI (últimos 30 días)',
          data: items.map((item: any) => [
            item.fecha,
            +item['Media UCI'] || 0,
          ]),
        },
        {
          name: 'Media hospitalizados (últimos 30 días)',
          data: items.map((item: any) => [
            item.fecha,
            +item['Media hospitalizados'] || 0,
          ]),
        },
        {
          name: 'UCI diarios (últimos 30 días)',
          data: items.map((item: any) => [
            item.fecha,
            +item['UCI diarios'] || 0,
          ]),
        },
      ]
    }

    onBeforeMount(async () => {
      await loadData()
    })

    return {
      series,
      chartOptions,
    }
  },
})
