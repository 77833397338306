













import { defineComponent } from 'vue-demi'

export default defineComponent({
  name: 'App',

  setup () {
    document.title = 'Covid charts'
  },
})
