var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hello"},[(_vm.series)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"area","height":"250","options":Object.assign({}, _vm.chartOptions,
            {title: {
              text: 'Hospitalizados diarios (últimos 30 días)',
              align: 'left'
            }}),"series":[_vm.series[0]]}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"area","height":"250","options":Object.assign({}, _vm.chartOptions,
            {title: {
              text: 'Media hospitalizados (últimos 30 días)',
              align: 'left'
            }}),"series":[_vm.series[2]]}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"area","height":"250","options":Object.assign({}, _vm.chartOptions,
            {title: {
              text: 'Media UCI (últimos 30 días)',
              align: 'left'
            }}),"series":[_vm.series[1]]}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"area","height":"250","options":Object.assign({}, _vm.chartOptions,
            {title: {
              text: 'UCI diarios (últimos 30 días)',
              align: 'left'
            }}),"series":[_vm.series[3]]}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }