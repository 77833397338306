






import Chart from '@/components/Chart.vue'
import { defineComponent } from 'vue-demi'

export default defineComponent({
  name: 'Home',

  components: { Chart },
})
